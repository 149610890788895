import React from "react";
import { useMsal } from "@azure/msal-react";
import Markdown from "react-markdown";

import { NxuAlert, NxuComponentLoading } from "@nexford/nexford-ui-component-library";
import CardPanel from "components/atom/card-panel";

import { useAuthContext } from "utils/context/Auth";
import { usePrivacyAgreementMarkdown, usePrivacySetting } from "utils/hooks/learners";

import "./privacy-content.scss";

/**
 * Privacy content section with form
 */
const PrivacyContent = () => {
  const { instance } = useMsal();
  const { userAccount } = useAuthContext();

  const {
    data: privacyAgreement,
    isLoading: privacyAgreementLoading,
    isError: privacyAgreementError,
  } = usePrivacyAgreementMarkdown(!!userAccount, instance);

  const {
    data: privacySetting,
    isLoading: privacySettingLoading,
    error: privacySettingError,
  } = usePrivacySetting(!!userAccount, instance);

  const MarkdownContent = () => {
    if (privacyAgreementLoading) {
      return <NxuComponentLoading />;
    }
    if (privacyAgreementError) {
      return <div>There was an error on loading the current Privacy Agreement document</div>;
    }

    return <section>{privacyAgreement?.Content && <Markdown>{privacyAgreement?.Content}</Markdown>}</section>;
  };

  return (
    <div className="privacy-page__content" data-testid="privacy-page__content">
      <CardPanel>
        <h1>Privacy Settings</h1>
        {MarkdownContent()}
      </CardPanel>

      <CardPanel>
        {privacySettingLoading && <NxuComponentLoading testId="privacy-setting-loading" />}
        {privacySettingError && <NxuAlert message={privacySettingError.message} />}
        {privacySetting && <div>Privacy setting loaded</div>}
      </CardPanel>
    </div>
  );
};

export default PrivacyContent;
