import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useLocation, useNavigate } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import { fileTrayFullOutline, personOutline, documentLockOutline } from "ionicons/icons";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import { useAuthContext } from "utils/context/Auth";
import { useLearner } from "utils/hooks/learners";
import { LocalRoutes } from "constants/routes";
import { isTestEnv } from "constants/feature-flags";

import { PageContent } from "components/molecule/page-wrap/page-wrap";

import ProfileContent from "./profile-content";
import DocumentsContent from "./documents-content";
import AepDocumentsContent from "./aep-documents-content/aep-documents-content";
import PrivacyContent from "./privacy-content";

import "./profile.scss";

/**
 * Profile page with learner details & documents
 */
const ProfilePage = () => {
  const { instance } = useMsal();
  const { userAccount } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  const { data: learnerProfile } = useLearner(!!userAccount, instance);

  const [tabInitialised, setTabInitialised] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (!tabInitialised) {
      if (isTestEnv) {
        if (pathname === LocalRoutes.DOCUMENTS && tabIndex !== 2) {
          setTabIndex(2);
        } else if (pathname === LocalRoutes.PRIVACY && tabIndex !== 1) {
          setTabIndex(1);
        }
      } else if (pathname === LocalRoutes.DOCUMENTS && tabIndex !== 1) {
        setTabIndex(1);
      }
      setTabInitialised(true);
    }
  }, [pathname, tabIndex, tabInitialised]);

  useEffect(() => {
    if (tabInitialised) {
      if (isTestEnv) {
        if (tabIndex === 2 && pathname !== LocalRoutes.DOCUMENTS) {
          navigate(LocalRoutes.DOCUMENTS);
          window.dataLayer?.push({
            event: "page_view",
            page_location: LocalRoutes.DOCUMENTS,
          });
        } else if (tabIndex === 1 && pathname !== LocalRoutes.PRIVACY) {
          navigate(LocalRoutes.PRIVACY);
          window.dataLayer?.push({
            event: "page_view",
            page_location: LocalRoutes.PRIVACY,
          });
        } else if (tabIndex === 0 && pathname !== LocalRoutes.PROFILE) {
          navigate(LocalRoutes.PROFILE);
          window.dataLayer?.push({
            event: "page_view",
            page_location: LocalRoutes.PROFILE,
          });
        }
      } else {
        // eslint-disable-next-line
        if (tabIndex === 1 && pathname !== LocalRoutes.DOCUMENTS) {
          navigate(LocalRoutes.DOCUMENTS);
          window.dataLayer?.push({
            event: "page_view",
            page_location: LocalRoutes.DOCUMENTS,
          });
        } else if (tabIndex === 0 && pathname !== LocalRoutes.PROFILE) {
          navigate(LocalRoutes.PROFILE);
          window.dataLayer?.push({
            event: "page_view",
            page_location: LocalRoutes.PROFILE,
          });
        }
      }
    }
  }, [navigate, pathname, tabIndex, tabInitialised]);

  return (
    <PageContent className="profile-page">
      <div className="profile-page__logo" data-testid="profile-page__logo">
        <span>
          {learnerProfile?.firstName.substring(0, 1)}
          {learnerProfile?.lastName.substring(0, 1)}
        </span>
      </div>

      {tabInitialised && (
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList data-testid="profile-page__tablist">
            <Tab>
              <IonIcon icon={personOutline} />
              Profile
            </Tab>
            {isTestEnv && (
              <Tab>
                <IonIcon icon={documentLockOutline} />
                Privacy
              </Tab>
            )}
            <Tab>
              <IonIcon icon={fileTrayFullOutline} />
              Documents
            </Tab>
          </TabList>

          <TabPanel>
            <ProfileContent />
          </TabPanel>
          {isTestEnv && (
            <TabPanel>
              <PrivacyContent />
            </TabPanel>
          )}
          <TabPanel>
            <DocumentsContent />
            <AepDocumentsContent />
          </TabPanel>
        </Tabs>
      )}
    </PageContent>
  );
};

export default ProfilePage;
